<template>
  <div id="katex-md">
    <span
      v-html="_expr"
      :class="{
        inline_text: inlineText,
        truncate: truncate,
      }"
    />
  </div>
</template>

<script>
import helpers from "../helpers"

import markdown_it from "markdown-it"
import mk from "@traptitech/markdown-it-katex"
import { full as emoji } from "markdown-it-emoji"
import highlightjs from "markdown-it-highlightjs"

export default {
  replace: true,
  props: {
    expr: {
      type: String,
      default: "",
    },
    linkify: { type: Boolean, default: true },
    onlyText: { type: Boolean, default: false },
    inlineText: { type: Boolean, default: false },
    removeAllHtml: { type: Boolean, default: false },
    truncate: { type: Boolean, default: false },
  },
  computed: {
    _expr: function(){
      const md = markdown_it({
        html: !this.onlyText,
        linkify: this.linkify,
        typography: !this.onlyText,
        breaks: !this.onlyText,
      })

      md.use(mk)
      md.use(emoji)
      md.use(highlightjs)

      const htmlOutput = md.render(this.expr)

      return this.removeAllHtml ? helpers.removeHTML(htmlOutput) : htmlOutput
    },
  },
}
</script>

<style>
  #katex-md span ul li{
    list-style-type: disc !important;
  }
  #katex-md span ul{
        padding-left: 2em;
  }

  #katex-md img {
    max-width: 100%;
  }

  #katex-md .inline_text p{
    display: inline;
  }

  #katex-md .truncate > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }


</style>
